
export const environment = {
      production: true,
      api_url: 'https://api-staging.integrate.io/',
      dashboardUrl: 'undefined',
      authToken: 'undefined',
      HUBSPOT_URL: 'https://xplenty-gateway-staging.herokuapp.com/send-hubspot-leads',
      SEGMENT_KEY: '9UNuKs9jM7Bq9jxfpwmytbOng4utBler',
      HUBSPOT_CONFIRMATION_WEBHOOK_URL: 'https://xplenty-gateway-staging.herokuapp.com/confirm-elt-lead',
      SOURCE_VERSION: 'deb69031284a7082e9c84c7f649da006cf68d20f',
      SENTRY_ENV: 'staging',
      SENTRY_DSN: 'https://c1488dcd61abc554f8f2353651afbef6@o4507576419680256.ingest.us.sentry.io/4507808147898368',
};
